@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .separator-right {
    border-right: 1px solid;
    border-bottom: 0;
    border-left: 0;
    border-top: 0;
    border-image: linear-gradient(
        1800deg,
        rgba(221, 221, 221, 0) 0%,
        rgba(221, 221, 221, 1) 20%,
        rgba(221, 221, 221, 1) 80%,
        rgba(221, 221, 221, 0) 100%
      )
      1;
  }

  .separator-top {
    border-top: 1px solid;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-image: linear-gradient(
        90deg,
        rgba(221, 221, 221, 0) 0%,
        rgba(221, 221, 221, 1) 20%,
        rgba(221, 221, 221, 1) 80%,
        rgba(221, 221, 221, 0) 100%
      )
      1;
  }
  .separator-left {
    border-left: 1px solid;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-image: linear-gradient(
        1800deg,
        rgba(221, 221, 221, 0) 0%,
        rgba(221, 221, 221, 1) 20%,
        rgba(221, 221, 221, 1) 80%,
        rgba(221, 221, 221, 0) 100%
      )
      1;
  }
  .separator-bottom {
    border-bottom: 1px solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-image: linear-gradient(
        90deg,
        rgba(221, 221, 221, 0) 0%,
        rgba(221, 221, 221, 1) 20%,
        rgba(221, 221, 221, 1) 80%,
        rgba(221, 221, 221, 0) 100%
      )
      1;
  }

  .separator-none {
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
}

@keyframes progress {
  0% {
    width: 0;
  }

  80% {
    width: 80%;
  }

  85% {
    width: 82%;
  }

  100% {
    width: 99%;
  }
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
  min-height: 100vh;
  min-height: fill-available;
}

#__next {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-template-columns: minmax(0, 1fr);
}

Header {
  grid-row: 1;
}

.main-content {
  grid-row: 2;
  /* Ensure the main content fills available space */
  display: flex;
  flex-direction: column;
}

.footer-content {
  grid-row: 3;
}

.intercom-namespace .intercom-messenger-frame,
.intercom-lightweight-app,
iframe.intercom-launcher-frame {
  @apply z-itercom !important;
}

.alma-payment-plans-container {
  width: auto;
  padding: 0;
  background: none;
}

@media (max-width: 1024px) {
  Header {
    grid-row: 2;
  }

  .main-content {
    grid-row: 3;
    /* Ensure the main content fills available space */
    display: flex;
    flex-direction: column;
  }

  .footer-content {
    grid-row: 4;
  }
}
